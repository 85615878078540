import baseApi from "../../core/lib/base";
import {MySingleTicketModel, MyTicketsResponseModel, PaymentPayload} from "../../types/tickets";

export const promoAPi = baseApi.injectEndpoints({
    endpoints: (builder) => ({

        verifyPromoCode: builder.mutation<any, { promo: string }>({
            query: (payload) => {
                return {
                    method: "GET",
                    url: `/promo-code/code/${payload.promo}'}`,
                };
            },
            onQueryStarted: async (_args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                } catch (error: any) {
                    throw error;
                }
            },
        }),

    }),
});

export const { useVerifyPromoCodeMutation } = promoAPi;
